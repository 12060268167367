import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import React from 'react';

import Main from './modules';
import { QueryClient, QueryClientProvider } from 'react-query';

import './i18n.ts';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Main />
    </QueryClientProvider>
  </React.StrictMode>
);

reportWebVitals();
