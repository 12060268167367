import React, { useRef, useEffect, useState } from 'react';
import * as faceDetection from '@tensorflow-models/face-detection';
import '@tensorflow/tfjs-backend-cpu';
import '@tensorflow/tfjs-backend-wasm';
import '@tensorflow/tfjs-converter';
import '@tensorflow/tfjs-core';
import '@mediapipe/face_detection';

import './../Shared/styles.css';
import './styles.css';
import { useTranslation } from 'react-i18next';

const FaceDetectionApp: React.FC = () => {
  const { t } = useTranslation();

  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const detectorRef = useRef<faceDetection.FaceDetector | null>(null);

  const [isVideoPlaying, setIsVideoPlaying] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isFaceDetected, setIsFaceDetected] = useState(false);

  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const setupFaceDetector = async () => {
    const model = faceDetection.SupportedModels.MediaPipeFaceDetector;
    detectorRef.current = await faceDetection.createDetector(model, {
      runtime: 'mediapipe',
      solutionPath: 'https://cdn.jsdelivr.net/npm/@mediapipe/face_detection',
    });
  };

  const startDetection = async () => {
    setupFaceDetector().then(() => {
      setTimeout(() => {
        setIsVideoPlaying(true);
        setIsFaceDetected(false);
        detectFace();
      }, 2000);
    });
  };

  const startVideo = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: { width: 640, height: 480 },
      });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        const playPromise = videoRef.current.play();

        if (playPromise !== undefined) {
          playPromise
            .then(() => {
              console.log('Video is playing');
              startDetection();
            })
            .catch((error) => {
              console.warn('Error playing the video:', error);
            });
        }
      }
    } catch (error) {
      console.error('Error accessing the camera', error);
      window.location.reload();
    }
  };

  const stopVideo = () => {
    if (videoRef.current) {
      const stream = videoRef.current.srcObject as MediaStream;
      const tracks = stream.getTracks();

      tracks.forEach((track) => track.stop());
      videoRef.current.srcObject = null;
      setIsVideoPlaying(false);
    }
  };

  const detectFace = async () => {
    if (videoRef.current && detectorRef.current) {
      try {
        const faces = await detectorRef.current.estimateFaces(videoRef.current);

        console.log('Faces:', faces);

        if (faces.length > 0) {
          const face = faces[0];
          const { xMin, xMax, yMin, yMax } = face.box;

          const faceCenterX = (xMin + xMax) / 2;
          const faceCenterY = (yMin + yMax) / 2;

          const videoWidth = videoRef.current.videoWidth;
          const videoHeight = videoRef.current.videoHeight;

          const ovalCenterX = videoWidth / 2;
          const ovalCenterY = videoHeight / 2;

          const ovalRadiusX = 100;
          const ovalRadiusY = 150;

          const distanceX = Math.abs(ovalCenterX - faceCenterX);
          const distanceY = Math.abs(ovalCenterY - faceCenterY);

          if (distanceX < ovalRadiusX && distanceY < ovalRadiusY) {
            captureImage();
            stopVideo();
            setIsLoading(true);
            setIsFaceDetected(true);
            setTimeout(() => {
              setIsLoading(false);

              // Restart video if necessary
              startVideo();
            }, 1000);
            return;
          }
        }

        if (isVideoPlaying && !isFaceDetected) {
          await delay(1000);

          requestAnimationFrame(detectFace);
        }
      } catch (error) {
        console.error('Error in face detection:', error);
        if (isVideoPlaying && !isFaceDetected) {
          await delay(1000);

          requestAnimationFrame(detectFace);
        }
      }
    }
  };

  const captureImage = () => {
    if (canvasRef.current && videoRef.current) {
      const context = canvasRef.current.getContext('2d');
      if (context) {
        context.drawImage(
          videoRef.current,
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height
        );
        const imageData = canvasRef.current.toDataURL('image/jpeg');
        window.parent.postMessage({ image: imageData }, '*');
      }
    }
  };

  useEffect(() => {
    startVideo();
  }, []);

  return (
    <div className="main">
      <div id="container">
        <div className="center-text">
          <h1 className="roboto-medium">
            {t('center_your_face_on_the_camera')}
          </h1>
        </div>
        <div className="video-container">
          <video id="video" ref={videoRef} autoPlay></video>
          <div className="overlay"></div>
          {isLoading && (
            <div className="loading-spinner">
              <p className="roboto-regular">{t('loading')}</p>
            </div>
          )}
        </div>
      </div>
      <canvas
        ref={canvasRef}
        width="300"
        height="400"
        style={{ display: 'none' }}
      ></canvas>
    </div>
  );
};

export default FaceDetectionApp;
