import { Amplify } from 'aws-amplify';
import awsexports from '../../config/aws-exports';
import { LivenessError } from '@aws-amplify/ui-react-liveness/dist/types/components/FaceLivenessDetector/service';
import { useSearchParams } from 'react-router-dom';

Amplify.configure(awsexports);

export const useFaceLiveness = () => {
  const [searchParams] = useSearchParams();
  const handleAnalysisComplete: () => Promise<void> = async () => {
    try {
      if (window.ReactNativeWebView) {
        const payload = {
          checkFaceSuccess: true,
        };

        window.ReactNativeWebView.postMessage(JSON.stringify(payload));
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleError = (livenessError?: LivenessError) => {
    console.log('error', livenessError);
    const payload = {
      checkFaceSuccess: false,
    };
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify(payload));
    }
  };

  return {
    searchParams,
    sessionId: searchParams.get('sessionId') ?? '',
    handleAnalysisComplete,
    handleError,
  };
};
