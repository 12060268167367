import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness';
import { ThemeProvider } from '@aws-amplify/ui-react';
import { useFaceLiveness } from './useFaceLiveness';
import '@aws-amplify/ui-react/styles.css';

const FaceLivenessScreen = () => {
  const { sessionId, handleAnalysisComplete, handleError } = useFaceLiveness();

  return (
    <ThemeProvider>
      <FaceLivenessDetector
        sessionId={sessionId}
        region="us-east-1"
        onAnalysisComplete={handleAnalysisComplete}
        onError={handleError}
        displayText={{
          hintCenterFaceText: 'Centralize seu rosto',
          photosensitivityWarningHeadingText: 'Aviso de fotossensibilidade',
          photosensitivityWarningBodyText:
            'Esta tela pode piscar cores diferentes. Tenha cuidado se você for fotossensível.',
          photosensitivityWarningInfoText:
            'Algumas pessoas podem ser sensíveis a luzes piscando.',
          waitingCameraPermissionText: 'Aguardando permissão da câmera',
          landscapeHeaderText: 'Gire seu dispositivo',
          landscapeMessageText:
            'Por favor, gire seu dispositivo para o modo retrato.',
          startScreenBeginCheckText: 'Começar verificação',
          hintConnectingText: 'Conectando',
          serverHeaderText: 'Verificação de identidade',
          serverMessageText: 'Não foi possível conectar ao servidor',
          tryAgainText: 'Tentar novamente',
          hintHoldFaceForFreshnessText: 'Fique parado',
          hintMoveFaceFrontOfCameraText: 'Aproxime seu rosto',
          cameraNotFoundHeadingText: 'Câmera não encontrada',
          connectionTimeoutHeaderText: 'Tempo de conexão esgotado',
          cameraMinSpecificationsHeadingText:
            'Especificações mínimas da câmera',
          timeoutHeaderText: 'Tempo esgotado',
          hintTooCloseText: 'Aproxime-se',
          cancelLivenessCheckText: 'Cancelar verificação',
          clientHeaderText: 'Verificação de identidade',
          clientMessageText: 'Não foi possível conectar ao servidor',
          tooFarCaptionText: 'Aproxime-se',
          tooFarAltText: 'Aproxime-se',
          timeoutMessageText: 'Tempo esgotado',
          retryCameraPermissionsText: 'Tentar novamente',
          recordingIndicatorText: 'Gravando',
          portraitMessageText:
            'Por favor, gire seu dispositivo para o modo retrato.',
          multipleFacesMessageText: 'Apenas uma pessoa por vez',
          multipleFacesHeaderText: 'Múltiplos rostos detectados',
          hintVerifyingText: 'Verificando',
          hintTooManyFacesText: 'Apenas uma pessoa por vez',
          hintTooFarText: 'Aproxime-se',
          hintMatchIndicatorText: 'Verificando',
          hintIlluminationTooDarkText: 'Iluminação insuficiente',
          hintIlluminationNormalText: 'Iluminação normal',
          photosensitivityWarningLabelText: 'Aviso de fotossensibilidade',
          hintIlluminationTooBrightText: 'Iluminação muito forte',
          hintCheckCompleteText: 'Verificação completa',
          hintFaceOffCenterText: 'Centralize seu rosto',
          hintFaceDetectedText: 'Rosto detectado',
          hintCenterFaceInstructionText: 'Centralize seu rosto',
          hintCanNotIdentifyText: 'Não foi possível identificar',
          cameraMinSpecificationsMessageText: '',
          a11yVideoLabelText: 'Vídeo',
          connectionTimeoutMessageText: 'Tempo de conexão esgotado',
          errorLabelText: 'Erro',
          goodFitAltText: 'Bom ajuste',
          faceDistanceHeaderText: 'Distância do rosto',
          faceDistanceMessageText: 'Aproxime-se',
          goodFitCaptionText: 'Bom ajuste',
          cameraNotFoundMessageText: 'Câmera não encontrada',
        }}
      />
    </ThemeProvider>
  );
};

export default FaceLivenessScreen;
