import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import FaceLivenessNativeScreen from './FaceLivenessNative/FaceLivenessScreen';
import FaceAPILogin from './FaceAPILogin/FaceAPILogin';

const Main = () => {
  return (
    <Router>
      <Routes>
        <Route path="/native" element={<FaceLivenessNativeScreen />} />
        <Route path="/login" element={<FaceAPILogin />} />
      </Routes>
    </Router>
  );
};

export default Main;
